import React from 'react';
import axios from 'axios';
import Layout from '../components/Layout';



class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: ''
    }
  }

  handleSubmit(e){
    e.preventDefault();
    axios({
      method: "POST",
      url:"https://prod-28.centralus.logic.azure.com:443/workflows/ce29f378395245b08090935fa449a6c9/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bzJehT9XgM_dLsB_yJTaocQo_kCATKbwtOH1Y-SgSlQ",
      data:  this.state
    }).then((response)=>{
      console.log(response);
      if (response.status === 202) {
        window.location.assign('/');
        this.resetForm()
      } else if (response.status !== 202) {
        alert("Message failed to send.")
      }
    })
  }

  resetForm(){
    this.setState({name: '', email: '', message: ''})
  }

  render() {
    return(
      <Layout fullMenu>

      <section id="cta" className="wrapper style2 special">
    <div className="inner">

      <div className="App">


        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
        <h2>Join our email list</h2>
        <p>We routinely send our current availability and pricing</p>

          <div className="row gtr-uniform">

							<div className="col-12 col-12-xsmall">
								<input type="email" name="register-email" id="email" defaultValue="" placeholder="Email" aria-describedby="emailHelp" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
							</div>
							<div className="col-12">
								<ul className="actions">
									<li><input type="submit" value="Sign up" className="primary" /></li>
									
								</ul>
							</div>
						</div>
        </form>
      </div>
      </div>
     
      </section>
        
      </Layout>
    );
  }

  onNameChange(event) {
	  this.setState({name: event.target.value})
  }

  onEmailChange(event) {
	  this.setState({email: event.target.value})
  }

  onMessageChange(event) {
	  this.setState({message: event.target.value})
  }
}

export default App;
